import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   redirect: '/transact',
  // },
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    // path: "/home",
    // name: "home",
    component: () => import('@/views/IndexView.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '溯源进万家'
        },
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: '/my',
        name: 'my',
        meta: {
          title: "个人中心"
        },
        component: () => import('@/views/MyView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录溯源进万家'
    },
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    meta: {
      title: '商品列表'
    },
    component: () => import('@/views/ProductListView.vue')
  },
  {
    path: '/addressList',
    name: 'AddressList',
    meta: {
      title: '地址列表'
    },
    component: () => import('@/views/AddressList.vue')
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    meta: {
      title: '地址修改'
    },
    component: () => import('@/views/AddressEdit.vue')
  },
  {
    path: '/submitOrder',
    name: 'SubmitOrder',
    meta: {
      title: '填写订单'
    },
    component: () => import('@/views/SubmitOrderView.vue')
  },
  {
    path: '/submitOrderRent',
    name: 'SubmitOrderRent',
    meta: {
      title: '填写订单'
    },
    component: () => import('@/views/SubmitOrderRentView.vue')
  },
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    meta: {
      title: '商品详情'
    },
    component: () => import('@/views/ProductDetails.vue')
  },
  {
    path: '/LeaseDetails',
    name: 'LeaseDetails',
    meta: {
      title: '换机说明'
    },
    component: () => import('@/views/LeaseDetails.vue')
  },

  {
    path: '/transact',
    name: 'transact',
    meta: {
      title: '立即办理'
    },
    component: () => import('@/views/transact.vue')
  },
  {
    path: '/answer',
    name: 'answer',
    meta: {
      title: '换机详情'
    },
    component: () => import('@/views/answer.vue')
  },{
    path: '/customerService',
    name: 'customerService',
    meta: {
      title: '咨询有奖'
    },
    component: () => import('@/views/customerService.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})





export default router
